@import 'core/settings/settings';
@import 'core/tools/tools';

@include themify('alert') using ($component) {
  /**
     * Color variants
     */
  $variants: map-get($component, 'variants');
  @each $color, $variant in $variants {
    .alert--#{$color} {
      background-color: map-get($variant, 'background');
      color: map-get($variant, 'text');
      box-shadow: map-get($variant, 'shadow');

      $border: map-get($variant, 'border');
      @if $border {
        border: map-get($variant, 'border');
      }
    }
  }
}

.alert {
  font-family: 'Nunito Sans', sans-serif;
  font-size: map-get($font-size, 300);
  font-weight: 600;

  width: fit-content;
  padding: map-get($spacing, 100) map-get($spacing, 300);
  margin: 0;
  
  display: flex;
  align-items: center;
  gap: map-get($spacing, 100);

  fo-icon {
    width: 32px;
    height: 32px;
  }

  // Small
  &--small {
    font-size: map-get($font-size, 100);
    fo-icon {
      width: 24px;
      height: 24px;
    }
  }
}
